import React, { ChangeEvent } from "react";
import "./styles.scss";
import VMasker from "vanilla-masker";

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputStyle?: any;
  mask?: string;
  value: string;
  onChangeInput: (a: string) => void;
  label?: string;
  isWarning?: boolean;
  whiteLabel?: boolean;
}

const InputText: React.FC<InputTextProps> = (props) => {
  const {
    inputStyle,
    value,
    mask,
    onChangeInput,
    label,
    isWarning,
    whiteLabel = false,
    ...rest
  } = props;

  const handlerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (mask) {
      onChangeInput(VMasker.toPattern(inputValue, mask));
    } else {
      onChangeInput(inputValue);
    }
  };

  return (
    <div id="input-text-default">
      {label && (
        <label
          className={`label-input-text ${whiteLabel ? "white-label" : ""} `}
        >
          {label}
        </label>
      )}
      <input
        className={`${inputStyle} ${isWarning ? "is-warning" : ""}`}
        value={value}
        onChange={handlerInput}
        {...rest}
      />
    </div>
  );
};

export default InputText;
