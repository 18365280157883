import React, { ReactNode } from "react";
import "./styles.scss";
import ArrowLeft from "../../../assets/icons/arrow-left-gray.svg";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quintenary"
    | "white-button";
  arrowIcon?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { buttonStyle, children, arrowIcon, ...rest } = props;
  return (
    <button
      id="button-default"
      className={`${buttonStyle}${
        buttonStyle === "quintenary" ? " background-blur-gray " : ""
      }`}
      {...rest}
    >
      {children}
      {arrowIcon && <img src={ArrowLeft} alt="arrow right" />}
    </button>
  );
};

export default Button;
