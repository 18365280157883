import React, { useState, useRef, useCallback } from "react";
import "./styles.scss";
import ArrowLeft from "../../../assets/icons/navigationIcons/arrow-left-gray.svg";

interface SlideCarouselProps {
  slides: Slides[];
  className?: string;
  hasArrows?: boolean;
  dotsColor?: string;
  loopSlides?: boolean;
  maxDots?: number;
}

interface Slides {
  backgroundImage: string;
  id: string | number;
}

const SlideCarousel: React.FC<SlideCarouselProps> = (props) => {
  const {
    slides,
    dotsColor = "var(--white)",
    loopSlides = false,
    hasArrows = true,
    className,
    maxDots = 6, 
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      loopSlides
        ? (prevIndex + 1) % slides.length
        : Math.min(prevIndex + 1, slides.length - 1)
    );
  }, [slides.length, loopSlides]);

  const goToPreviousSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      loopSlides
        ? prevIndex === 0
          ? slides.length - 1
          : prevIndex - 1
        : Math.max(prevIndex - 1, 0)
    );
  }, [slides.length, loopSlides]);

  const getVisibleDots = () => {
    const totalDots = slides.length;
    const halfMaxDots = Math.floor(maxDots / 2);

    if (totalDots <= maxDots) {
      return slides.map((_, index) => index);
    }

    if (currentIndex <= halfMaxDots) {
      return slides.slice(0, maxDots).map((_, index) => index);
    }

    if (currentIndex >= totalDots - halfMaxDots) {
      return slides
        .slice(totalDots - maxDots, totalDots)
        .map((_, index) => index + totalDots - maxDots);
    }

    return slides
      .slice(currentIndex - halfMaxDots, currentIndex + halfMaxDots + 1)
      .map((_, index) => index + currentIndex - halfMaxDots);
  };

  return (
    <div id="slide-carousel" className={className ?? ""}>
      <div id="slide-carousel-images">
        {slides.map((slide, index) => (
          <div key={`${index} ${slide.id}`} className="main-slide-wrapper">
            <img
              className={`slide-carousel-slide ${
                index === currentIndex ? "active" : ""
              }`}
              key={index}
              src={slide.backgroundImage}
              alt=""
            />
          </div>
        ))}

        {hasArrows && slides.length > 1 && (
          <>
            <button
              className="carousel-button prev"
              onClick={goToPreviousSlide}
            >
              <img src={ArrowLeft} alt="arrow left" />
            </button>
            <button className="carousel-button next" onClick={goToNextSlide}>
              <img src={ArrowLeft} alt="arrow right" />
            </button>
          </>
        )}

        {slides.length > 1 && (
          <div className="footer-dots-button">
            <div className="pagination-dots-wrapper">
              {getVisibleDots().map((index) => (
                <div
                  onClick={() => setCurrentIndex(index)}
                  key={index}
                  className={`pagination-dot ${
                    index === currentIndex ? "current-dot" : ""
                  } `}
                  style={{
                    borderColor: dotsColor,
                    opacity: index === currentIndex ? 1 : 0.5,
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideCarousel;
