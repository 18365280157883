import React, { ReactNode } from "react";
import "./styles.scss";
import Footer from "../../Footer";
import FadeCarousel from "../../Carousels/FadeCarousel";
import NavBarDefault from "../../NavBars/NavBarDefault";

interface LayoutDefaultProps {
  children: ReactNode;
  className?: string;
  hasImageInFooter?: boolean;
  slidesCarousel?: { backgroundImage: string; id: string | number }[];
  hasBusinessCard?: boolean;
}

const LayoutDefault: React.FC<LayoutDefaultProps> = (props) => {
  const {
    children,
    className,
    hasImageInFooter,
    slidesCarousel,
    hasBusinessCard,
  } = props;

  return (
    <div id="layout-default">
      <NavBarDefault />
      {slidesCarousel && (
        <FadeCarousel slides={slidesCarousel} duration={12000} />
      )}

      <main className={`main-container ${className ?? ""}`}>{children}</main>

      <Footer
        hasImageInFooter={hasImageInFooter}
        className="padding-hor-24px"
        hasBusinessCard={hasBusinessCard}
      />
    </div>
  );
};

export default LayoutDefault;
