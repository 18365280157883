import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import LogoWhite from "../../../assets/logos/logo-text-white.svg";
import LogoBlue from "../../../assets/logos/logo-text.svg";

const NavBar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleTriggerClick = () => {
    const navWrapper = document.querySelector("#nav-bar-landing-page");
    if (navWrapper) {
      navWrapper.classList.toggle("open");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="nav-bar-landing-page" className={isScrolled ? "scrolled" : ""}>
      <div
        className="logo-container"
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          onClick={() => navigate("/")}
          src={isScrolled ? LogoBlue : LogoWhite}
          alt="Thiago Costa's logo"
        />
      </div>
      <div
        className={
          isScrolled
            ? "button-container-nav-bar scrolled"
            : "button-container-nav-bar"
        }
      >
        <ul className="nav no-margin no-padding">
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/#contact-us">Contact Us</a>
          </li>
          <a
            href="https://wa.me/13053704780"
            target="_blank"
            rel="noopener noreferrer"
          >
            +1 (305) 370-4780
          </a>
        </ul>
      </div>
      <div
        className={
          isScrolled ? "trigger margin-top-8 scrolled" : "trigger margin-top-8"
        }
        onClick={handleTriggerClick}
      ></div>
    </div>
  );
};

export default NavBar;
