export const jadeSignatureInfo = {
  principal: [
    {
      id: 0,
      name: "Bedrooms",
      quantity: "5",
    },
    {
      id: 1,
      name: "Bathrooms",
      quantity: "6",
    },
    {
      id: 2,
      name: "Half Bath",
      quantity: "1",
    },
    {
      id: 3,
      name: "SqFt",
      quantity: "6,513",
    },
    {
      id: 5,
      name: "Built",
      quantity: "2018",
    },
  ],

  specifications: [
    { id: 0, key: "Living Area", value: "6,513 sqft" },
    { id: 1, key: "Outdoor Living", value: "1,407 sqft" },
    { id: 2, key: "Bedrooms", value: "5" },
    { id: 3, key: "Bathrooms", value: "6" },
    { id: 4, key: "Half Bathroom", value: "1" },
    { id: 5, key: "Year Built", value: "2018" },
    { id: 6, key: "Garage Spaces", value: "3" },
    { id: 7, key: "Waterfront Property", value: "Yes" },
    { id: 8, key: "Community", value: "Jade Signature" },
    { id: 9, key: "Association Fee", value: "$9,943" },
    { id: 10, key: "Property Tax (2023)", value: "$141,051.81" },
  ],
};
