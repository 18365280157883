import React from "react";
import "./styles.scss";

interface TextAreaProps {
  value: string;
  onChangeInput: (event: any) => void;
  label?: string;
  placeholder?: string;
  rows?: number;
	isWarning?:boolean;
}

const TextArea: React.FC<TextAreaProps> = (props) => {
  const { onChangeInput, value, label, placeholder, rows = 4 , isWarning} = props;
  return (
    <div id="text-area-default">
      <label>{label}</label>
      <textarea
        placeholder={placeholder}
        onChange={(e) => onChangeInput(e.target.value)}
        value={value}
        rows={rows}
				className={isWarning ? "is-warning" : ""}
      />
    </div>
  );
};

export default TextArea;
