import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import ImageAndText from "../../components/ImageAndText";
import YouTubeFrame from "../../components/YouTubeFrame";
import { jadeSignatureInfo } from "../../mocks/projectInfo";
import Hr from "../../components/Hr";
import HomeListDetails from "../../components/HomeListDetails";

//IMAGES
import jadeSignature5105_01 from "../../assets/images/jade-signature/jadeSignature5105_01.jpg";
import jadeSignature5105_02 from "../../assets/images/jade-signature/jadeSignature5105_02.jpg";
import jadeSignature5105_03 from "../../assets/images/jade-signature/jadeSignature5105_03.jpg";
import jadeSignature5105_04 from "../../assets/images/jade-signature/jadeSignature5105_04.jpg";
import jadeSignature5105_05 from "../../assets/images/jade-signature/jadeSignature5105_05.jpg";
import jadeSignature5105_06 from "../../assets/images/jade-signature/jadeSignature5105_06.jpg";
import jadeSignature5105_07 from "../../assets/images/jade-signature/jadeSignature5105_07.jpg";
import jadeSignature5105_08 from "../../assets/images/jade-signature/jadeSignature5105_08.jpg";
import jadeSignature5105_09 from "../../assets/images/jade-signature/jadeSignature5105_09.jpg";
import jadeSignature5105_10 from "../../assets/images/jade-signature/jadeSignature5105_10.jpg";
import jadeSignature5105_11 from "../../assets/images/jade-signature/jadeSignature5105_11.jpg";
import jadeSignature5105_12 from "../../assets/images/jade-signature/jadeSignature5105_12.jpg";
import jadeSignature5105_13 from "../../assets/images/jade-signature/jadeSignature5105_13.jpg";
import jadeSignature5105_14 from "../../assets/images/jade-signature/jadeSignature5105_14.jpg";
import jadeSignature5105_15 from "../../assets/images/jade-signature/jadeSignature5105_15.jpg";
import jadeSignature5105_16 from "../../assets/images/jade-signature/jadeSignature5105_16.jpg";
import jadeSignature5105_17 from "../../assets/images/jade-signature/jadeSignature5105_17.jpg";
import jadeSignature5105_18 from "../../assets/images/jade-signature/jadeSignature5105_18.jpg";
import jadeSignature5105_19 from "../../assets/images/jade-signature/jadeSignature5105_19.jpg";
import jadeSignature5105_20 from "../../assets/images/jade-signature/jadeSignature5105_20.jpg";
import jadeSignature5105_21 from "../../assets/images/jade-signature/jadeSignature5105_21.jpg";
import jadeSignature5105_22 from "../../assets/images/jade-signature/jadeSignature5105_22.jpg";
import jadeSignature5105_23 from "../../assets/images/jade-signature/jadeSignature5105_23.jpg";
import jadeSignature5105_24 from "../../assets/images/jade-signature/jadeSignature5105_24.jpg";
import jadeSignature5105_25 from "../../assets/images/jade-signature/jadeSignature5105_25.jpg";
import jadeSignature5105_26 from "../../assets/images/jade-signature/jadeSignature5105_26.jpg";
import jadeSignature5105_27 from "../../assets/images/jade-signature/jadeSignature5105_27.jpg";
import jadeSignature5105_28 from "../../assets/images/jade-signature/jadeSignature5105_28.jpg";
import jadeSignature5105_29 from "../../assets/images/jade-signature/jadeSignature5105_29.jpg";
import jadeSignature5105_30 from "../../assets/images/jade-signature/jadeSignature5105_30.jpg";
import jadeSignature5105_31 from "../../assets/images/jade-signature/jadeSignature5105_31.jpg";
import jadeSignature5105_32 from "../../assets/images/jade-signature/jadeSignature5105_32.jpg";
import jadeSignature5105_33 from "../../assets/images/jade-signature/jadeSignature5105_33.jpg";
import jadeSignature5105_34 from "../../assets/images/jade-signature/jadeSignature5105_34.jpg";
import jadeSignature5105_35 from "../../assets/images/jade-signature/jadeSignature5105_35.jpg";
import jadeSignature5105_36 from "../../assets/images/jade-signature/jadeSignature5105_36.jpg";
import jadeSignature5105_37 from "../../assets/images/jade-signature/jadeSignature5105_37.jpg";
import jadeSignature5105_38 from "../../assets/images/jade-signature/jadeSignature5105_38.jpg";
import jadeSignature5105_39 from "../../assets/images/jade-signature/jadeSignature5105_39.jpg";
import jadeSignature5105_40 from "../../assets/images/jade-signature/jadeSignature5105_40.jpg";
import jadeSignature5105_41 from "../../assets/images/jade-signature/jadeSignature5105_41.jpg";
import jadeSignature5105_42 from "../../assets/images/jade-signature/jadeSignature5105_42.jpg";
import jadeSignature5105_43 from "../../assets/images/jade-signature/jadeSignature5105_43.jpg";
import jadeSignature5105_44 from "../../assets/images/jade-signature/jadeSignature5105_44.jpg";
import jadeSignature5105_45 from "../../assets/images/jade-signature/jadeSignature5105_45.jpg";
import jadeSignature5105_46 from "../../assets/images/jade-signature/jadeSignature5105_46.jpg";
import jadeSignature5105_47 from "../../assets/images/jade-signature/jadeSignature5105_47.jpg";
import jadeSignature5105_48 from "../../assets/images/jade-signature/jadeSignature5105_48.jpg";
import jadeSignature5105_49 from "../../assets/images/jade-signature/jadeSignature5105_49.jpg";
import jadeSignature5105_50 from "../../assets/images/jade-signature/jadeSignature5105_50.jpg";
import jadeSignature5105_51 from "../../assets/images/jade-signature/jadeSignature5105_51.jpg";
import jadeSignature5105_52 from "../../assets/images/jade-signature/jadeSignature5105_52.jpg";
import jadeSignature5105_53 from "../../assets/images/jade-signature/jadeSignature5105_53.jpg";
import jadeSignature5105_54 from "../../assets/images/jade-signature/jadeSignature5105_54.jpg";
import jadeSignature5105_55 from "../../assets/images/jade-signature/jadeSignature5105_55.jpg";
import jadeSignature5105_56 from "../../assets/images/jade-signature/jadeSignature5105_56.jpg";
import jadeSignature5105_57 from "../../assets/images/jade-signature/jadeSignature5105_57.jpg";
import jadeSignature5105_58 from "../../assets/images/jade-signature/jadeSignature5105_58.jpg";
import jadeSignature5105_59 from "../../assets/images/jade-signature/jadeSignature5105_59.jpg";
import jadeSignature5105_60 from "../../assets/images/jade-signature/jadeSignature5105_60.jpg";
import jadeSignature5105_61 from "../../assets/images/jade-signature/jadeSignature5105_61.jpg";
import jadeSignature5105_62 from "../../assets/images/jade-signature/jadeSignature5105_62.jpg";
import jadeSignature5105_63 from "../../assets/images/jade-signature/jadeSignature5105_63.jpg";
import jadeSignature5105_64 from "../../assets/images/jade-signature/jadeSignature5105_64.jpg";
import jadeSignature5105_65 from "../../assets/images/jade-signature/jadeSignature5105_65.jpg";
import jadeSignature5105_66 from "../../assets/images/jade-signature/jadeSignature5105_66.jpg";
import jadeSignature5105_67 from "../../assets/images/jade-signature/jadeSignature5105_67.jpg";
import jadeSignature5105_68 from "../../assets/images/jade-signature/jadeSignature5105_68.jpg";
import jadeSignature5105_69 from "../../assets/images/jade-signature/jadeSignature5105_69.jpg";
import jadeSignature5105_70 from "../../assets/images/jade-signature/jadeSignature5105_70.jpg";
import jadeSignature5105_71 from "../../assets/images/jade-signature/jadeSignature5105_71.jpg";
import jadeSignature5105_72 from "../../assets/images/jade-signature/jadeSignature5105_72.jpg";
import jadeSignature5105_73 from "../../assets/images/jade-signature/jadeSignature5105_73.jpg";
import jadeSignature5105_74 from "../../assets/images/jade-signature/jadeSignature5105_74.jpg";
import jadeSignature5105_75 from "../../assets/images/jade-signature/jadeSignature5105_75.jpg";
import jadeSignature5105_76 from "../../assets/images/jade-signature/jadeSignature5105_76.jpg";
import jadeSignature5105_77 from "../../assets/images/jade-signature/jadeSignature5105_77.jpg";
import jadeSignature5105_78 from "../../assets/images/jade-signature/jadeSignature5105_78.jpg";
import jadeSignature5105_79 from "../../assets/images/jade-signature/jadeSignature5105_79.jpg";
import jadeSignature5105_80 from "../../assets/images/jade-signature/jadeSignature5105_80.jpg";
import jadeSignature5105_81 from "../../assets/images/jade-signature/jadeSignature5105_81.jpg";
import jadeSignature5105_82 from "../../assets/images/jade-signature/jadeSignature5105_82.jpg";
import jadeSignature5105_83 from "../../assets/images/jade-signature/jadeSignature5105_83.jpg";
import jadeSignature5105_84 from "../../assets/images/jade-signature/jadeSignature5105_84.jpg";
import jadeSignature5105_85 from "../../assets/images/jade-signature/jadeSignature5105_85.jpg";
import jadeSignature5105_86 from "../../assets/images/jade-signature/jadeSignature5105_86.jpg";
import jadeSignature5105_87 from "../../assets/images/jade-signature/jadeSignature5105_87.jpg";
import jadeSignature5105_88 from "../../assets/images/jade-signature/jadeSignature5105_88.jpg";
import jadeSignature5105_89 from "../../assets/images/jade-signature/jadeSignature5105_89.jpg";
import jadeSignature5105_90 from "../../assets/images/jade-signature/jadeSignature5105_90.jpg";
import jadeSignature5105_91 from "../../assets/images/jade-signature/jadeSignature5105_91.jpg";
import jadeSignature5105_92 from "../../assets/images/jade-signature/jadeSignature5105_92.jpg";
import jadeSignature5105_93 from "../../assets/images/jade-signature/jadeSignature5105_93.jpg";
import jadeSignature5105_94 from "../../assets/images/jade-signature/jadeSignature5105_94.jpg";
import jadeSignature5105_95 from "../../assets/images/jade-signature/jadeSignature5105_95.jpg";
import jadeSignature5105_96 from "../../assets/images/jade-signature/jadeSignature5105_96.jpg";
import jadeSignature5105_97 from "../../assets/images/jade-signature/jadeSignature5105_97.jpg";
import jadeSignature5105_98 from "../../assets/images/jade-signature/jadeSignature5105_98.jpg";
import jadeSignature5105_99 from "../../assets/images/jade-signature/jadeSignature5105_99.jpg";
import jadeSignature5105_100 from "../../assets/images/jade-signature/jadeSignature5105_100.jpg";
import jadeSignature5105_101 from "../../assets/images/jade-signature/jadeSignature5105_101.jpg";
import jadeSignature5105_102 from "../../assets/images/jade-signature/jadeSignature5105_102.jpg";
import jadeSignature5105_103 from "../../assets/images/jade-signature/jadeSignature5105_103.jpg";
import jadeSignature5105_104 from "../../assets/images/jade-signature/jadeSignature5105_104.jpg";
import jadeSignature5105_105 from "../../assets/images/jade-signature/jadeSignature5105_105.jpg";
import jadeSignature5105_106 from "../../assets/images/jade-signature/jadeSignature5105_106.jpg";
import jadeSignature5105_107 from "../../assets/images/jade-signature/jadeSignature5105_107.jpg";
import jadeSignature5105_108 from "../../assets/images/jade-signature/jadeSignature5105_108.jpg";
import jadeSignature5105_109 from "../../assets/images/jade-signature/jadeSignature5105_109.jpg";
import jadeSignature5105_110 from "../../assets/images/jade-signature/jadeSignature5105_110.jpg";
import jadeSignature5105_111 from "../../assets/images/jade-signature/jadeSignature5105_111.jpg";
import jadeSignature5105_112 from "../../assets/images/jade-signature/jadeSignature5105_112.jpg";
import jadeSignature5105_113 from "../../assets/images/jade-signature/jadeSignature5105_113.jpg";

//IMAGES BUILDING
import jadeSignatureBuilding01 from "../../assets/images/jade-signature/jade-signature-building01.jpg";
import jadeSignatureBuilding02 from "../../assets/images/jade-signature/jade-signature-building02.jpg";
import jadeSignatureBuilding03 from "../../assets/images/jade-signature/jade-signature-building03.jpg";
import jadeSignatureBuilding04 from "../../assets/images/jade-signature/jade-signature-building04.jpg";
import jadeSignatureBuilding05 from "../../assets/images/jade-signature/jade-signature-building05.jpg";
import jadeSignatureBuilding06 from "../../assets/images/jade-signature/jade-signature-building06.jpg";
import jadeSignatureBuilding07 from "../../assets/images/jade-signature/jade-signature-building07.jpg";

//IMAGE MODEL
import jadeSignatureModel01 from "../../assets/images/jade-signature/modelo-01.jpg";
import jadeSignatureModel02 from "../../assets/images/jade-signature/modelo-02.jpg";
import jadeSignatureModel03 from "../../assets/images/jade-signature/modelo-03.jpg";
import jadeSignatureModel04 from "../../assets/images/jade-signature/modelo-04.jpg";
import jadeSignatureModel05 from "../../assets/images/jade-signature/modelo-05.jpg";
import jadeSignatureModel06 from "../../assets/images/jade-signature/modelo-06.jpg";
import jadeSignatureModel07 from "../../assets/images/jade-signature/modelo-07.jpg";
import jadeSignatureModel08 from "../../assets/images/jade-signature/modelo-08.jpg";

//IMAGE LOCATION
import sunnyIsles from "../../assets/images/jade-ocean/sunny-isles-beach-4004.jpg";
import sunnyIslesBeach from "../../assets/images/jade-signature/sunny-isles-beach.jpg";

//IMAGES AMENITIES
import jadeSignatureAmenities01 from "../../assets/images/jade-signature/jade-signature_amenities01.jpg";
import jadeSignatureAmenities02 from "../../assets/images/jade-signature/jade-signature_amenities02.jpg";
import jadeSignatureAmenities03 from "../../assets/images/jade-signature/jade-signature_amenities03.jpg";
import jadeSignatureAmenities04 from "../../assets/images/jade-signature/jade-signature_amenities04.jpeg";
import jadeSignatureAmenities05 from "../../assets/images/jade-signature/jade-signature_amenities05.png";
import jadeSignatureAmenities06 from "../../assets/images/jade-signature/jade-signature_amenities06.png";
import jadeSignatureAmenities07 from "../../assets/images/jade-signature/jade-signature_amenities07.png";
import jadeSignatureAmenities08 from "../../assets/images/jade-signature/jade-signature_amenities08.png";
import jadeSignatureAmenities09 from "../../assets/images/jade-signature/jade-signature_amenities09.png";
import jadeSignatureAmenities10 from "../../assets/images/jade-signature/jade-signature_amenities10.png";
import jadeSignatureAmenities11 from "../../assets/images/jade-signature/jade-signature_amenities11.png";
import jadeSignatureAmenities12 from "../../assets/images/jade-signature/jade-signature_amenities12.png";
import jadeSignatureAmenities13 from "../../assets/images/jade-signature/jade-signature_amenities13.png";
import jadeSignatureAmenities14 from "../../assets/images/jade-signature/jade-signature_amenities14.png";
import jadeSignatureAmenities15 from "../../assets/images/jade-signature/jade-signature_amenities15.png";
import jadeSignatureAmenities16 from "../../assets/images/jade-signature/jade-signature_amenities16.png";
import jadeSignatureAmenities17 from "../../assets/images/jade-signature/jade-signature_amenities17.jpg";
import jadeSignatureAmenities18 from "../../assets/images/jade-signature/jade-signature_amenities18.jpg";
import jadeSignatureAmenities19 from "../../assets/images/jade-signature/jade-signature_amenities19.jpg";
import jadeSignatureAmenities20 from "../../assets/images/jade-signature/jade-signature_amenities20.jpg";
import jadeSignatureAmenities21 from "../../assets/images/jade-signature/jade-signature_amenities21.jpg";
import jadeSignatureAmenities22 from "../../assets/images/jade-signature/jade-signature_amenities22.jpg";
import jadeSignatureAmenities23 from "../../assets/images/jade-signature/jade-signature_amenities23.jpg";
import jadeSignatureAmenities24 from "../../assets/images/jade-signature/jade-signature_amenities24.jpg";
import jadeSignatureAmenities25 from "../../assets/images/jade-signature/jade-signature_amenities25.jpg";
import jadeSignatureAmenities26 from "../../assets/images/jade-signature/jade-signature_amenities26.jpg";

const LandingPage: React.FC = () => {
  const [wideScreen, setWideScreen] = useState(window.innerWidth < 765);

  useEffect(() => {
    const handleResize = () => {
      setWideScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <LayoutDefault
      hasImageInFooter
      slidesCarousel={[
        { id: "1", backgroundImage: jadeSignatureBuilding02 },
        { id: "3", backgroundImage: jadeSignatureBuilding03 },
        { id: "2", backgroundImage: jadeSignatureBuilding04 },
        { id: "4", backgroundImage: jadeSignatureBuilding01 },
        { id: "5", backgroundImage: jadeSignatureBuilding05 },
      ]}
      className="landing-page"
    >
      <div className="main-info">
        <h3>Sky Villa at Jade Signature | Unit 5105</h3>
        <p>
          Introducing the North Sky Villa 5105 at Jade Signature, an
          architectural masterpiece perched on the 51st and 52nd floors of this
          renowned 57-story oceanfront tower. Designed by the world-famous
          Herzog & de Meuron, and decorated by Artefacto, this sky villa is the
          epitome of modern elegance. Situated in Sunny Isles Beach, a prime
          location between Bal Harbour and Aventura, this home offers
          unparalleled access to luxury shopping, world-class dining, and the
          vibrant lifestyle of Miami​.
        </p>
      </div>

      <YouTubeFrame videoURL={"https://www.youtube.com/watch?v=9koZOusB25s"} />

      <HomeListDetails
        details={jadeSignatureInfo.principal}
        specifications={jadeSignatureInfo.specifications}
      />

      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeSignatureModel01 }]}
        position="vert-text-photo"
        gallery
        textTitle="A Masterpiece of Elevated Oceanfront Living at Jade Signature"
        textParagraph={`The Sky Villa 5105 at Jade Signature is a one-of-a-kind, two-story luxury residence that redefines the standard for elevated beachfront living. Spread across an impressive 6,513 square feet of interior space and complemented by an additional 1,407 square feet of outdoor terraces, this sky villa offers a seamless blend of expansive indoor and outdoor living. As you step into this masterpiece, you're greeted by soaring 20-foot double-height ceilings in the grand living area, which is bathed in natural light through floor-to-ceiling windows that frame sweeping, unobstructed views of both the Atlantic Ocean and the Intracoastal Waterway.
`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeSignatureModel02 }]}
        position="hor-text-photo"
        textTitle="Amazing 180° Panoramic Views"
        textParagraph={`Perched high above the pristine shores of Sunny Isles Beach, Sky Villa 5105 offers some of the most breathtaking, uninterrupted views in all of Miami. Floor-to-ceiling windows frame the sparkling Atlantic Ocean to the east and the tranquil Intracoastal Waterway to the west, providing residents with a seamless connection to nature’s grandeur. From dawn to dusk, the ever-changing vistas create a stunning backdrop for every moment of the day—witness spectacular sunrises over the ocean and golden sunsets over the city skyline.
`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeSignatureModel03 }]}
        position="hor-photo-text"
        textTitle="A Culinary Masterpiece"
        textParagraph={`The kitchen in Sky Villa 5105 is a sophisticated blend of design and functionality, crafted to satisfy the most discerning tastes. Featuring sleek European cabinetry by Snaidero, the space exudes modern elegance, while top-of-the-line appliances from Gaggenau and Sub-Zero ensure every culinary need is met with precision. From the built-in wine cooler and cappuccino maker to the speed oven and double refrigerators, this kitchen is equipped for both casual meals and grand entertaining. Every detail, from the quartz countertops to the integrated storage solutions, has been meticulously chosen to enhance both the aesthetics and functionality of this culinary masterpiece.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeSignatureModel04 }]}
        position="hor-text-photo"
        textTitle="A Private Sanctuary: The Exquisite Suite"
        textParagraph={`The master bedroom in Sky Villa 5105 is the ultimate expression of luxury and serenity, offering a personal retreat high above the vibrant shores of Sunny Isles Beach. This expansive space is designed to embrace relaxation, with floor-to-ceiling windows that flood the room with natural light and provide breathtaking views of the Atlantic Ocean. The sleek design is enhanced by rich materials and finishes, creating a modern yet inviting atmosphere that is both elegant and tranquil. The custom-designed closet spaces feature ample room for wardrobe collections, with thoughtfully integrated storage solutions to keep everything perfectly organized. Luxurious finishes, sleek lines, and attention to detail make these closets not only functional but a beautiful extension of the master suite’s sophisticated design.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeSignatureModel05 },
          { id: "2", img: jadeSignatureModel06 },
          { id: "3", img: jadeSignatureModel07 },
          { id: "4", img: jadeSignatureModel08 },
        ]}
        position="vert-photo-text"
        gallery
        textTitle="Elevated Outdoor Living: Expansive Terraces with Panoramic Views"
        textParagraph={`The balconies of Sky Villa 5105 offer 1,407 square feet of luxurious outdoor living, seamlessly extending the villa’s interior with frameless glass railings that provide unobstructed views of the Atlantic Ocean and Intracoastal Waterway. Designed for both relaxation and entertainment, these expansive terraces include a built-in outdoor grille, transforming the space into a true outdoor kitchen, perfect for al fresco dining. With direct access from the main living areas, the flow-through design ensures a smooth transition between indoor and outdoor spaces, while shaded areas provide comfort without compromising the breathtaking panoramic views. This outdoor oasis is an ideal setting for hosting gatherings or enjoying the serene beauty of Miami’s skyline​.`}
      />

      <Hr />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeSignature5105_02 },
          { id: "2", img: jadeSignature5105_05 },
          { id: "3", img: jadeSignature5105_12 },
          { id: "4", img: jadeSignature5105_13 },
          { id: "5", img: jadeSignature5105_07 },
          { id: "6", img: jadeSignature5105_18 },
          { id: "7", img: jadeSignature5105_19 },
          { id: "8", img: jadeSignature5105_21 },
          { id: "9", img: jadeSignature5105_22 },
          { id: "10", img: jadeSignature5105_25 },
          { id: "11", img: jadeSignature5105_26 },
          { id: "12", img: jadeSignature5105_27 },
          { id: "13", img: jadeSignature5105_30 },
          { id: "14", img: jadeSignature5105_31 },
          { id: "15", img: jadeSignature5105_35 },
          { id: "16", img: jadeSignature5105_36 },
          { id: "17", img: jadeSignature5105_40 },
          { id: "18", img: jadeSignature5105_43 },
          { id: "19", img: jadeSignature5105_50 },
          { id: "20", img: jadeSignature5105_51 },
          { id: "21", img: jadeSignature5105_52 },
          { id: "22", img: jadeSignature5105_53 },
          { id: "23", img: jadeSignature5105_55 },
          { id: "24", img: jadeSignature5105_56 },
          { id: "25", img: jadeSignature5105_57 },
          { id: "26", img: jadeSignature5105_58 },
          { id: "27", img: jadeSignature5105_59 },
          { id: "28", img: jadeSignature5105_60 },
          { id: "29", img: jadeSignature5105_61 },
          { id: "30", img: jadeSignature5105_64 },
          { id: "31", img: jadeSignature5105_66 },
          { id: "32", img: jadeSignature5105_70 },
          { id: "33", img: jadeSignature5105_72 },
          { id: "34", img: jadeSignature5105_74 },
          { id: "35", img: jadeSignature5105_76 },
          { id: "36", img: jadeSignature5105_79 },
          { id: "37", img: jadeSignature5105_84 },
          { id: "38", img: jadeSignature5105_85 },
          { id: "39", img: jadeSignature5105_88 },
          { id: "40", img: jadeSignature5105_91 },
          { id: "41", img: jadeSignature5105_96 },
          { id: "42", img: jadeSignature5105_99 },
          { id: "43", img: jadeSignature5105_100 },
          { id: "44", img: jadeSignature5105_102 },
          { id: "45", img: jadeSignature5105_104 },
          { id: "46", img: jadeSignature5105_105 },
          { id: "47", img: jadeSignature5105_108 },
          { id: "48", img: jadeSignature5105_109 },
          { id: "49", img: jadeSignature5105_111 },
          { id: "50", img: jadeSignature5105_113 },
        ]}
        gallery
        position="vert-text-photo"
        textTitle="Experience the epitome of luxury living at Jade Signature"
        textParagraph={`At the Sky Villa 5105, where Artefacto's world-class interior design meets the breathtaking architecture of Jade Signature. Every space is thoughtfully curated with modern elegance, combining sleek lines, rich textures, and refined materials to create a seamless blend of comfort and sophistication. Floor-to-ceiling windows flood the interiors with natural light, offering stunning views that complement the serene, neutral color palette. From the grand living areas to the intimate bedrooms, this unit showcases the pinnacle of elevated living, where every detail is designed to inspire and indulge.`}
      />

      <Hr />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeSignatureBuilding06 }]}
        position="vert-text-photo"
        gallery
        textTitle="The Unrivaled Amenities of Jade Signature: A New Standard in Luxury Living"
        textParagraph={`Jade Signature offers an extraordinary collection of amenities designed to enhance every aspect of modern luxury living. From wellness and relaxation to entertainment and convenience, this tower provides a resort-like experience that caters to all lifestyles.
`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeSignatureAmenities03 },
          { id: "2", img: jadeSignatureAmenities02 },
          { id: "3", img: jadeSignatureAmenities01 },
          { id: "4", img: jadeSignatureAmenities04 },
        ]}
        position="hor-photo-text"
        textTitle="Beachfront Resort Deck"
        textParagraph={`The resort deck is the heart of outdoor relaxation, featuring an organic, free-form swimming pool with zero-entry access, surrounded by lush landscaped gardens, secluded cabanas, and a hot tub. A private beach area offers chaise lounges, umbrellas, and even a children's sandbox at the shoreline, ensuring residents can enjoy the pristine sands of Sunny Isles Beach in ultimate comfort.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeSignatureAmenities05 },
          { id: "2", img: jadeSignatureAmenities06 },
          { id: "3", img: jadeSignatureAmenities07 },
          { id: "4", img: jadeSignatureAmenities08 },
          { id: "1", img: jadeSignatureAmenities09 },
          { id: "2", img: jadeSignatureAmenities10 },
          { id: "3", img: jadeSignatureAmenities11 },
          { id: "4", img: jadeSignatureAmenities12 },
          { id: "1", img: jadeSignatureAmenities13 },
          { id: "2", img: jadeSignatureAmenities14 },
          { id: "3", img: jadeSignatureAmenities15 },
          { id: "4", img: jadeSignatureAmenities16 },
        ]}
        position="hor-text-photo"
        textTitle="Spa & Wellness Center"
        textParagraph={`Located on the third floor, the exclusive Spa offers a full range of rejuvenating treatments, including massages, facials, and manicures, in a serene environment overlooking the ocean. The wellness center also includes a coed hammam, sauna, steam room, and a water therapy terrace with three temperature-controlled Jacuzzis. For those seeking fitness, the state-of-the-art gym offers oceanfront views, along with dedicated studios for Pilates, spinning, and yoga.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeSignatureAmenities17 },
          { id: "2", img: jadeSignatureAmenities19 },
          { id: "3", img: jadeSignatureAmenities20 },
          { id: "4", img: jadeSignatureAmenities21 },
        ]}
        position="hor-photo-text"
        textTitle="Dining & Entertainments"
        textParagraph={`Jade Signature elevates social gatherings with a beachfront bar and grill, serving poolside and beachside, as well as a demonstration kitchen for private events. The entertainment lounge includes billiards, poker tables, and a wine bar, perfect for both intimate get-togethers and larger celebrations. For formal events, the exclusive clubroom and business center offer refined spaces for gatherings or meetings.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeSignatureAmenities22 },
          { id: "2", img: jadeSignatureAmenities23 },
          { id: "3", img: jadeSignatureAmenities24 },
        ]}
        position="hor-text-photo"
        textTitle="Children’s & Teens’ Areas"
        textParagraph={`Families enjoy dedicated spaces for younger residents, including a toddlers' sensory room, a tech-savvy teen lounge with interactive games, and a children’s playroom equipped with video games and a smart board. These thoughtfully designed areas ensure every age group has its own place to enjoy.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeSignatureAmenities26 }]}
        position="hor-photo-text"
        textTitle="Concierge & Services"
        textParagraph={`Jade Signature’s 24-hour concierge service, doorman, and valet provide unparalleled convenience, while underground parking, electric car charging stations, and even a car wash area ensure all practical needs are met with ease. The seamless flow of indoor-outdoor spaces, including private elevator lobbies and beachfront mail rooms, enhances the living experience.`}
      />

      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: sunnyIslesBeach }]}
        position="hor-text-photo"
        textTitle="The Location: Sunny Isles Beach"
        textParagraph={`Sunny Isles Beach stands as one of South Florida's most 
					sought-after neighborhoods, celebrated for its pristine beaches, 
					upscale shopping, and gourmet dining experiences. Located on the 
					iconic Collins Avenue, Jade Signature places residents just moments from 
					the vibrant offerings of the area, including world-class shopping at 
					Aventura Mall, fine dining, and endless entertainment options. The 
					neighborhood is also home to top-rated schools, making it a perfect 
					setting for families, while its close proximity to major highways and 
					airports ensures effortless travel to Miami’s hotspots and beyond.`}
        linkTo={{
          linkHref: "https://maps.app.goo.gl/HCtZEwxtco7Xt2iq7",
          linkText: "16901 COLLINS AVE | SUNNY ISLES BEACH, FL",
        }}
      />
    </LayoutDefault>
  );
};

export default LandingPage;
