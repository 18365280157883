import React from "react";
import "./styles.scss";
import Button from "../Buttons/Button";

interface HomeListDetailsProps {
  details: {
    id: number;
    name: string;
    quantity: string | number;
  }[];
  specifications: {
    id: number;
    key: string;
    value: string;
  }[];
}

const HomeListDetails: React.FC<HomeListDetailsProps> = (props) => {
  const { details, specifications } = props;

  return (
    <div className="home-list-details">
      <h3 className="margin-bottom-24">Specifications</h3>

      <div className="cards-specifications">
        {details.map((detail) => (
          <div key={detail.id} className="card">
            <h5 className="no-margin">{detail.name}</h5>
            <p className="no-margin">{detail.quantity}</p>
          </div>
        ))}
      </div>

      <ul className="list-specifications padding-top-24">
        {specifications.map((specification) => (
          <li key={specification.id}>
            <p className="list-key no-margin">{specification.key}</p>
            <p className="list-value no-margin">{specification.value}</p>
          </li>
        ))}
      </ul>
      <div className="action-buttons">
        <Button
          children={"Download Brochure"}
          buttonStyle={"primary"}
          onClick={() => {
            const link = document.createElement("a");
            link.href = `${process.env.PUBLIC_URL}/Jade-Signature_Brochure.pdf`;
            link.download = "Jade-Signature_Brochure.pdf";
            link.click();
          }}
        />
        <Button
          children={"Download Floor Plan"}
          buttonStyle={"secondary"}
          onClick={() => {
            const link = document.createElement("a");
            link.href = `${process.env.PUBLIC_URL}/Jade Signature North Sky Villa 5105 Floorplan.pdf`;
            link.download = "Jade Signature North Sky Villa 5105 Floorplan.pdf";
            link.click();
          }}
        />
      </div>
    </div>
  );
};

export default HomeListDetails;
