import { createBrowserRouter } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import AboutUsPage from "../pages/AboutUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
	{
    path: "/about-us",
    element: <AboutUsPage />,
  },
]);

export default router;
