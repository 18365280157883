import React from "react";
import "./styles.scss";
import EmailIcon from "../../assets/icons/social-medias/email-icon.svg";
import InstagramLogo from "../../assets/icons/social-medias/instagram-logo.svg";
import YoutubeLogo from "../../assets/icons/social-medias/youtube-logo.svg";
import Hr from "../Hr";
import BusinessCard from "../BusinessCard";
import firLogo from "../../assets/images/fortune-christies-logo.png";
import xLogo from "../../assets/icons/social-medias/x-icon.svg";
import linkedinLogo from "../../assets/icons/social-medias/linkedin-icon.svg";

interface FooterProps {
  hasImageInFooter?: boolean;
  className?: string;
  hasBusinessCard?: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
  const { hasImageInFooter, className, hasBusinessCard = true } = props;

  return (
    <footer id="footer-default" className={className ?? ""}>
      <Hr />

      {hasBusinessCard && (
        <>
          <BusinessCard
            className="margin-vert-40"
            hasImageInFooter={hasImageInFooter}
          />
          <Hr />
        </>
      )}

      <div className="copy-right margin-top-80">
        <div className="logos-container">
          <img src={firLogo} alt="Fortune Logo" />
        </div>
        <p className="disclaimer">
          Thiago Costa • Miami | Copyright © | All Rights Reserved 2024
        </p>
        <div className="social-medias">
          <a href="mailto:thiago.costa@fortuneintl.com">
            <img src={EmailIcon} alt="email icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/thiagocosta-miami/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedinLogo} alt="linkedin icon" />
          </a>
          <a
            href="https://twitter.com/thiagoREbroker"
            target="_blank"
            rel="noreferrer"
          >
            <img src={xLogo} alt="x icon" />
          </a>
          <a
            href="https://www.instagram.com/thiagocosta.miami/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramLogo} alt="instagram icon" />
          </a>
          <a
            href="https://www.youtube.com/@thiagocosta-miami"
            target="_blank"
            rel="noreferrer"
          >
            <img src={YoutubeLogo} alt="youtube icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
