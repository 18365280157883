import { IContact } from "../types/IContact";
import { routeApi } from "./services/environments";

const SendEmailContact = async (templateData: IContact) => {
  try {
    const response = await fetch(`${routeApi}/send-contact-email`, {
      method: "POST",
			headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(templateData),
    });
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return { res: await response.json(), status: response.status };
  } catch (error) {
    return { res: error, status: 0 };
  }
};

export default SendEmailContact;
