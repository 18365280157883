import React from "react";
import "./styles.scss";

interface YouTubeFrameProps {
  videoURL?: string;
  videoId?: string;
}

const YouTubeFrame: React.FC<YouTubeFrameProps> = (props) => {
  const { videoURL, videoId } = props;
  const extractId = videoURL?.split("v=")[1];
  const ID = videoId ? videoId : extractId;

  const getVideoUrl = (videoId: string) => {
    return `https://www.youtube.com/embed/${videoId}?autoplay=0`;
  };
  return (
    <div id="you-tube-frame">
      <iframe
        src={getVideoUrl(ID as string)}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
        className="video-iframe"
      ></iframe>
    </div>
  );
};

export default YouTubeFrame;
